import _toStringTagSupport from "../internals/to-string-tag-support";
import _defineBuiltIn from "../internals/define-built-in";
import _objectToString from "../internals/object-to-string";
var TO_STRING_TAG_SUPPORT = _toStringTagSupport;
var defineBuiltIn = _defineBuiltIn;
var toString = _objectToString;

// `Object.prototype.toString` method
// https://tc39.es/ecma262/#sec-object.prototype.tostring
if (!TO_STRING_TAG_SUPPORT) {
  defineBuiltIn(Object.prototype, "toString", toString, {
    unsafe: true
  });
}
export default {};